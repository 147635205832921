<template>
  <div class="form-default-time">
    <a-radio-group v-model="type" :options="radioOptions" />
    <template v-if="type == 'custom'">
      <a-time-picker
        v-model="time"
        value-format="HH:mm"
        format="HH:mm"
        v-if="mode == 'time'" />
      <a-date-picker
        v-model="time"
        :show-time="mode == 'date-time' ? {format: 'HH:mm'} : false"
        :value-format="format"
        :format="format"
        v-else />
    </template>
  </div>
</template>

<script>
    /**
     * 日期时间默认值表单组件
     * 支持v-model语法糖
     * @returns 0 当前时间/日期 YYYY-MM-DD 指定日期 YYYY-MM-DD HH:mm 指定时间
     */
    import { isEmpty } from "../../common/js/tool";

    export default {
        name: "default-time",
        model: {
            event: "change"
        },
        props: {
            mode: String,
            value: [String, Number]
        },
        data() {
            return {}
        },
        computed: {
            radioOptions() {
                let res = [];
                if(this.mode == 'time' || this.mode == 'date-time') {
                    res = [
                        { label: '当前时间', value: 0 },
                        { label: '指定时间', value: "custom" },
                    ]
                } else if(this.mode == 'date') {
                    res = [
                        { label: '当天', value: 0 },
                        { label: '指定日期', value: "custom" },
                    ]
                }
                return res;
            },
            format() {
                return this.mode == 'date-time' ? 'YYYY-MM-DD HH:mm' : 'YYYY-MM-DD';
            },
            type: {
                get() {
                    const value = this.value;
                    let res;
                    if(!isEmpty(value)) {
                        const val = Number(value);
                        res = isNaN(val) ? "custom" : val;
                    } else if(value === "") {
                        // 值为空字符串表示 指定日期/时间 但未选择具体日期/时间
                        res = "custom";
                    }
                    return res;
                },
                set(val) {
                    this.$emit("change", val == "custom" ? "" : val);
                }
            },
            time: {
                get() {
                    const value = this.value;
                    return value && value != "custom" && isNaN(Number(value)) ? value : undefined;
                },
                set(val) {
                    this.$emit("change", val || undefined);
                }
            },
        },
        methods: {

        }
    }
</script>

<style lang="less">

</style>
