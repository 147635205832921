<template>
  <div class="form-holiday-type">
    <a-radio-group v-model="type" :options="radioOptions" />
    <div class="holiday-type-select" v-show="type == 'custom'">
      <a-checkbox-group v-model="typeList">
        <a-row>
          <a-col :span="8" v-for="t in options || []" :key="t.key">
            <a-checkbox :value="t.key">{{ t.title }}</a-checkbox>
          </a-col>
        </a-row>
      </a-checkbox-group>
    </div>
  </div>
</template>

<script>
    export default {
        name: "holiday-type",
        model: {
            event: "change"
        },
        props: {
            options: Array,
            value: String
        },
        data() {
            return {
                radioOptions: [
                    { label: '所有类型', value: "all" },
                    { label: '指定类型', value: "custom" },
                ],
            }
        },
        computed: {
            type: {
                get() {
                    const value = this.value;
                    let res;
                    if(value === "all" || value === undefined) {
                        res = "all";
                    } else {
                        res = "custom";
                    }
                    return res;
                },
                set(val) {
                    this.dispatchChange(val);
                }
            },
            typeList: {
                get() {
                    let res = [];
                    const value = this.value;
                    const type = this.type;
                    if(type == "custom" && value) {
                        res = JSON.parse(value).map(item => item.key);
                    }
                    return res;
                },
                set(val) {
                    const options = this.options || [];
                    const items = options.filter(h => val.indexOf(h.key) >= 0);
                    this.$emit("change", items.length > 0 ? JSON.stringify(items) : "");
                }
            },
        },
        methods: {
            dispatchChange(type) {
                type = type || this.type;
                let res = type == "all" ? "all" : "";
                if(type == "custom") {
                    const options = this.options || [];
                    const items = options.filter(h => this.typeList.indexOf(h.key) >= 0);
                    if(items.length > 0) {
                        res = JSON.stringify(items)
                    }
                }
                this.$emit("change", res);
            }
        }
    }
</script>

<style lang="less">
.holiday-type-select {
  margin-top: 8px;
  padding-top: 12px;
  border-top: 1px solid @border-color-base;
}
</style>
