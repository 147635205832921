<template>
  <div class="value-validate">
    <div class="form-set-rules" v-if="rules && rules.length > 0">
      <div class="set-rule" v-for="(o, j) in rules" :key="j">
        <div class="rule-name">规则{{j + 1}}：{{o.title}}</div>
        <div class="rule-len" v-if="o.type == 3">
          <div class="len-title">区间</div>
          <a-input class="len-input" v-model="o.rule.min"></a-input>
          <div class="len-line"></div>
          <a-input class="len-input" v-model="o.rule.max"></a-input>
        </div>
        <div class="rule-len" v-else-if=" o.type == 4">
          <div class="len-title">区间</div>
          <a-input class="len-input" v-model="o.rule.small"></a-input>
          <div class="len-line"></div>
          <a-input class="len-input" v-model="o.rule.big"></a-input>
        </div>
        <a-input v-model="o.rule.regex" placeholder="校验正则表达式" style="margin-bottom: 6px"
                 v-if="o.type == 6"></a-input>
        <a-input v-model="o.rule.message" placeholder="请输入校验不通过提示信息"></a-input>
        <a-icon class="icon-close" type="close" @click.stop="removeRule(j)"/>
      </div>
    </div>
    <a-button type="primary" @click="addRule">添加规则</a-button>
    <a-modal
      title="选择校验类型"
      centered
      :width="300"
      @ok="confirmRule"
      v-model="visible">
      <a-select v-model="rule" placeholder="请选择校验类型" style="width: 100%">
        <a-select-option :key="i" :title="opt.title" :value="i" v-for="(opt, i) in ruleOptions">{{opt.title}}
        </a-select-option>
      </a-select>
    </a-modal>
  </div>
</template>

<script>

    import {rules} from "./formItems";
    import {clone, isEmpty} from "../../common/js/tool";

    export default {
        name: "value-validate",
        model: {
            event: "change"
        },
        props: {
            value: Array
        },
        computed: {
            rules: {
                get() {
                    return this.value || [];
                },
                set(val) {
                    this.$emit("change", val && val.length > 0 ? val : undefined);
                }
            }
        },
        data() {
            return {
                visible: false,
                rule: 0,
                ruleOptions: rules,
            }
        },
        methods: {
            addRule() {
                this.visible = true;
            },
            confirmRule() {
                let rules = [...this.rules];
                let rule = this.rule;
                if (isEmpty(rule)) {
                    this.$message.warning("请选择校验类型")
                } else {
                    rule = clone(this.ruleOptions[rule]);
                }
                if (rules) {
                    rules.push(rule);
                } else {
                    rules = [rule];
                }
                this.rules = rules;
                this.visible = false
            },
            removeRule(i) {
                const rules = [...this.rules];
                rules.splice(i, 1);
                this.rules = rules;
            },
        }
    }
</script>

<style lang="less">
  .form-set-rules {
    margin: -6px 0 20px;
  }

  .set-rule {
    position: relative;
    padding: 6px 10px;
    background-color: @background-color-light;

    &:not(:first-of-type) {
      margin-top: 6px;
    }

    &:hover .icon-close {
      display: block;
    }

    .icon-close {
      display: none;
      position: absolute;
      top: 0;
      right: 0;
      width: 16px;
      height: 16px;
      background-color: @error-color;
      cursor: pointer;
      font-size: 12px;
      line-height: 16px;
      text-align: center;
      color: @text-color-inverse;
    }
  }

  .rule-name {
    margin-bottom: 6px;
  }

  .rule-len {
    display: flex;
    align-items: center;
    margin-bottom: 6px;
  }

  .len-title {
    flex-shrink: 0;
    width: 48px;
  }

  .len-line {
    margin: 0 6px;
    width: 30px;
    height: 1px;
    background-color: @text-color-secondary;
  }
</style>
