<template>
  <div class="min-day-count">
    <a-space v-for="t in typeList" :key="t.key">
      <span>{{t.title}}</span>
      <a-radio-group :value="t.min" :options="countOptions" :default-value="0.5" @change="handleCountChange($event, t)" />
    </a-space>
  </div>
</template>

<script>
    import { staffTypes } from "../../common/hr/book";

    export default {
        name: "min-day-count",
        model: {
            event: "change"
        },
        props: {
            value: Object
        },
        data() {
            return {
                typeList: [...staffTypes],
                countOptions: [
                    { label: "0.5天", value: 0.5 },
                    { label: "1天", value: 1 },
                ]
            }
        },
        watch: {
            value() {
                this.setType();
            }
        },
        created() {
            this.setType();
        },
        methods: {
            setType() {
                const value = this.value;
                const typeList = [...staffTypes];
                if (value) {
                    typeList.forEach(t => {
                        if(value[t.key]) {
                            t.min = value[t.key];
                        }
                    });
                }
                this.typeList = typeList;
            },
            handleCountChange(e, type) {
                const value = e.target.value;
                this.$set(type, "min", value);
                this.dispatchChange();
            },
            dispatchChange() {
                const typeList = this.typeList;
                const res = {};
                typeList.forEach(t => {
                    if(t.min) {
                        res[t.key] = t.min;
                    }
                });
                this.$emit("change", res);
            }
        }
    }
</script>

<style lang="less">

</style>
