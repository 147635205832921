<template>
  <div class="meal-time-range">
    <a-select class="batch-set-item" v-model="start" :options="startOptions" placeholder="开始时间"/>
    <span class="meal-tim-gap">至</span>
    <a-select class="batch-set-item" v-model="end" :options="endOptions" placeholder="结束时间"/>
  </div>
</template>

<script>
    import {clone, isEmpty} from "../../common/js/tool";
    import { mealList } from "../../common/js/apply";
    export default {
        name: "meal-time-range",
        model: {
            event: "change"
        },
        props: {
            prev: Number,
            max: Number,
            value: Array
        },
        data() {
            return {}
        },
        computed: {
            options() {
                let res = [];
                let prev = this.prev, max = this.max;
                if(isEmpty(prev)) {
                    prev = 1;
                }
                if(isEmpty(max)) {
                    max = 2;
                }
                let days = [];
                days.push({
                    day: prev,
                    title: this.getDayTitle(prev)
                });
                for(let i = 1; i <= max; i++) {
                    days.push({
                        day: prev + i,
                        title: this.getDayTitle(prev + i)
                    });
                }
                const l = mealList.length;
                days.forEach((d, i) => {
                    res.push(...mealList.map((m, j) => {
                        return {
                            value: `${d.day},${j}`,
                            label: `${d.title}-${m}`,
                            index: i * l + j
                        }
                    }));
                })
                return res;
            },
            start: {
                get() {
                    let res;
                    const value = this.value;
                    if(value && value[0]) {
                        res = value[0];
                    }
                    return res;
                },
                set(val) {
                    this.dispatchChange(val, this.end);
                }
            },
            end: {
                get() {
                    let res;
                    const value = this.value;
                    if(value && value[1]) {
                        res = value[1];
                    }
                    return res;
                },
                set(val) {
                    this.dispatchChange(this.start, val);
                }
            },
            startOptions() {
                const options = clone(this.options);
                const end = this.end ? options.find(o => o.value == this.end) : null;
                if(end) {
                    options.forEach(o => {
                        if(o.index > end.index) {
                            o.disabled = true;
                        }
                    })
                }
                return options
            },
            endOptions() {
                const options = clone(this.options);
                const start = this.start ? options.find(o => o.value == this.start) : null;
                if(start) {
                    options.forEach(o => {
                        if(o.index < start.index) {
                            o.disabled = true;
                        }
                    })
                }
                return options
            }
        },
        methods: {
            getDayTitle(day) {
                let res = "";
                if(day < 0) {
                    res = `前${Math.abs(day)}天`
                } else if(day == 0) {
                    res =  "当天"
                } else {
                    res = `后${Math.abs(day)}天`
                }
                return res;
            },
            dispatchChange(start, end) {
                this.$emit("change", [start, end]);
            }
        }
    }
</script>

<style lang="less">
.meal-time-range {
  display: flex;
  align-items: center;
}
.meal-tim-gap {
  margin: 0 0.5em;
}
</style>
