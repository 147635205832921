<template>
    <div class="form-controls" v-show="show">
        <a-tabs default-active-key="1" @click.stop>
            <a-tab-pane key="1" tab="控件库">
                <div class="form-control-list">
                    <div class="form-control-item" v-for="(c, i) in list" :key="i" @click.stop="select(c)">
                        <div class="control-icon" :class="[c.icon]"></div>
                        <div class="control-title">{{c.title}}</div>
                    </div>
                </div>
            </a-tab-pane>
            <a-tab-pane key="2" tab="假勤组件">
                <div class="form-control-list">
                    <div class="form-control-item" v-for="(c, i) in attendance" :key="i" @click.stop="select(c)">
                        <div class="control-icon" :class="[c.icon]"></div>
                        <div class="control-title">{{c.title}}</div>
                    </div>
                </div>
                <div class="control-tip">
                    <div class="icon-tip control-tip-icon"></div>
                    <div class="control-tip-text">一个模板仅支持添加一种假勤组件。</div>
                </div>
            </a-tab-pane>
            <a-tab-pane key="3" tab="特殊组件">
                <div class="form-control-list">
                    <div class="form-control-item" v-for="(c, i) in special" :key="i" @click.stop="select(c)">
                        <div class="control-icon" :class="[c.icon]"></div>
                        <div class="control-title">{{c.title}}</div>
                    </div>
                </div>
                <div class="control-tip">
                    <div class="icon-tip control-tip-icon"></div>
                    <div class="control-tip-text">一个模板仅支持添加一个同一类型特殊组件。</div>
                </div>
            </a-tab-pane>
        </a-tabs>
    </div>
</template>

<script>
    import {formItems, attendItems, specialItems} from "./formItems";

    export default {
        name: "form-controls",
        data() {
            return {
                show: false,
                attendance: attendItems,
                special: specialItems,
                list: formItems
            }
        },
        mounted() {
            document.addEventListener("click", this.hide, false);
        },
        beforeDestroy() {
            document.removeEventListener("click", this.hide, false);
        },
        methods: {
            showControls() {
                this.show = true;
            },
            select(item) {
                this.$emit("select", item);
                this.hide();
            },
            hide() {
                this.show = false;
            }
        }
    }
</script>

<style lang="less">
    .form-controls {
        padding: 10px 30px;
        width: 402px;
        box-shadow: @box-shadow-base;
        background-color: @component-background;
        border: var(--border);
    }
    .form-control-list {
        margin: 20px -10px 0;
        width: 360px;
    }
    .form-control-item {
        display: inline-block;
        position: relative;
        margin: 0 10px 20px 10px;
        width: 70px;
        height: 58px;
        border: var(--border);
        cursor: pointer;
        overflow: hidden;
        text-align: center;
        font-size: 12px;
        &:hover {
            border-color: @primary-color;
            box-shadow: 0 0 6px #aed1f3;
        }
    }
    .control-icon {
        margin: 6px 0 4px;
        font-size: 24px;
        color: @text-color-secondary;
        line-height: 1;
    }
    .control-tip {
        display: flex;
        align-items: center;
        font-size: 12px;
    }
    .control-tip-icon {
        margin-right: 6px;
        color: @text-color-secondary;
    }
</style>
