<template>
  <div class="control-options">
    <div class="form-set-options">
      <div class="set-option" v-for="(o, j) in options" :key="j">
        <a-input v-model="options[j]" placeholder="请输入选项内容"></a-input>
        <div class="set-opt-edit">
          <a-icon type="minus" class="icon-minus" @click="removeOption(j)" v-if="options.length > 1"/>
          <a-icon type="plus" class="icon-plus" @click="addOption(j)"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
    export default {
        name: "control-options",
        model: {
            event: "change"
        },
        props: {
            value: Array
        },
        data() {
            return {}
        },
        computed: {
            options: {
                get() {
                    return this.value || [];
                },
                set(val) {
                    this.$emit("change", val && val.length > 0 ? val : undefined);
                }
            }
        },
        methods: {
            removeOption(i) {
                const options = [...this.options];
                if (options.length <= 1) return;
                options.splice(i, 1);
                this.options = options;
            },
            addOption(i) {
                const options = [...this.options];
                options.splice(i + 1, 0, `选项${options.length + 1}`);
                this.options = options;
            },
        }
    }
</script>

<style lang="less">
  .set-option {
    display: flex;

    &:not(:first-child) {
      margin-top: 6px;
    }
  }

  .set-opt-edit {
    display: flex;
    align-items: center;
    margin-left: 10px;
    height: 30px;

    .icon-minus,
    .icon-plus {
      padding: 3px;
      width: 18px;
      height: 18px;
      border-radius: 100%;
      background-color: @text-color-secondary;
      cursor: pointer;
      color: @component-background;
      font-size: 12px;
      line-height: 1;
    }

    .icon-plus {
      margin-left: 6px;
    }
  }
</style>
