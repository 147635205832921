/**
 * 平滑滚动类
 */

const easeInOutQuad = (t, b, c, d) => {
  if ((t/=d/2) < 1) return c/2*t*t + b;
  return -c/2 * ((--t)*(t-2) - 1) + b;
};


class Scroller {
  constructor (dom, duration) {
    this.dom = dom;
    this.duration = duration;
    this.h = dom.clientHeight;
    this.start = false;
  }
  scrollDistance(max) {
    if(max >= 0) {
      this.st = this.dom.scrollTop;
      this.distance = max - this.st;
      //有滚动未完成，更新起始时间
      if(this.start) {
        this.start = this.start + this.interval;
      } else {
        window.requestAnimationFrame(current => {this.scroll(current)});
      }
    }
  }
  scrollTo(ele) {
    let max;
    let top = ele.offsetTop + ele.clientHeight;
    if(top < this.h) {
      max = ele.offsetTop;
    } else {
      max = top - this.h;
    }
    this.scrollDistance(max);
  }
  scrollBottom () {
    let max = this.dom.scrollHeight - this.h;
    this.scrollDistance(max);
  }
  scroll (current) {
    if (!this.start) {
      this.start = current;
    }
    this.interval = current - this.start;
    let dis = easeInOutQuad(this.interval, this.st, this.distance, this.duration);
    this.dom.scrollTop = dis;

    this.interval < this.duration ? window.requestAnimationFrame(current => {this.scroll(current)}) : this.done()
  }
  done () {
    this.start = false;
  }
}

export default Scroller;
