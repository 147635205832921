<template>
  <div class="control-rely">
    <div class="flex-box align-center" style="height: 40px" v-if="show">
      <a-select v-model="key" placeholder="请选择依赖的表单控件" style="margin-right: 8px">
        <a-select-option :value="c.key" v-for="c in relyControls || []" :key="c.key"
                         :title="c.label">{{c.label}}
        </a-select-option>
      </a-select>
      <a-select
        v-model="value"
        placeholder="请选择依赖值"
        :options="getSelectOptions(control.options)"
        @change="dispatchChange"
        v-if="control.options">
      </a-select>
      <a-input v-model="value" placeholder="请输入依赖值" @input="dispatchChange" v-else-if="key"></a-input>
      <a-icon type="close-circle" theme="filled" class="clear-rely" @click="clearRely"/>
    </div>
    <a-button @click="setRely" v-else>设置</a-button>
  </div>
</template>

<script>

    export default {
        name: "control-rely",
        model: {
            event: "change",
            prop: "rely"
        },
        props: {
            rely: Object,
            relyControls: Array,
        },
        computed: {
            control() {
                let res = {};
                if(this.key && this.relyControls) {
                    res = this.relyControls.find(c => c.key == this.key) || {};
                }
                return res;
            },
        },
        data() {
            return {
                key: undefined,
                value: undefined,
                show: false,
            }
        },
        watch: {
            rely() {
                this.setKeyValue();
            }
        },
        created() {
            this.setKeyValue();
        },
        methods: {
            setKeyValue() {
                const rely = this.rely;
                if(rely) {
                    this.key = rely.key;
                    this.value = rely.value;
                    this.show = true;
                } else {
                    this.key = undefined;
                    this.value = undefined;
                    this.show = false;
                }
            },
            dispatchChange() {
                if(this.key && this.value) {
                    this.$emit("change", { key: this.key, value: this.value });
                } else {
                    this.$emit("change", undefined);
                }
            },
            setRely() {
                this.show = true;
            },
            clearRely() {
                this.key = undefined;
                this.value = undefined;
                this.show = false;
                this.dispatchChange();
            },
            getSelectOptions(options) {
                options = options || [];
                return options.map(opt => typeof opt !== "object" ? Object({key: opt, title: opt}) : opt);
            },
        }
    }
</script>

<style lang="less">
  .clear-rely {
    margin-left: 8px;
    font-size: 24px;
    color: #999;
    cursor: pointer;
  }
</style>
